import { createApp } from 'vue';
import App from './App.vue';
import router from './routes';
import store from './store';
import i18n from './i18n';
import { filters } from './filters/_globals';
import { createVuetify } from 'vuetify';
import VueCookies from 'vue-cookies';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import { ErrorMessage, configure } from 'vee-validate';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import 'dropzone-vue/dist/dropzone-vue.common.css';
import * as Sentry from '@sentry/vue';
import dayjs from 'dayjs';

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
dayjs.extend(isSameOrBefore);

//Vuetify
import 'vuetify/dist/vuetify.min.css';
import './sass/main.scss';
import '@mdi/font/css/materialdesignicons.css';
import * as mdiIcons from '@mdi/js';

//Vue social chat
import VueSocialChat from 'vue-social-chat';
import 'vue-social-chat/dist/style.css';

const theme = {
    dark: false,
    colors: {
        primary: '#37609D',
        secondary: '#52B788',
        accent: '#CDD7E6',
        error: '#E63946',
        info: '#CDD7E6',
        success: '#52B788',
        warning: '#FFEE99',
        'primary-lighten-1': '#CDD7E6',
        'primary-lighten-2': '#9DB8D6',
        white: '#fff',
        'green-base': '#52B788',
        'green-lighten1': '#52D5A6',
        'green-darken1': '#52996A',
        'red-base': '#E63946',
        'red-lighten1': '#E65764',
        'red-darken1': '#E61B28',
        'red-darken2': '#320C1A',
        'yellow-base': '#FFEE99',
        'yellow-lighten1': '#FFF2A0',
        'yellow-lighten2': '#FDF0C7',
        'yellow-darken1': '#F4D868',
        'blue-base': '#37609D',
        'blue-lighten1': '#7390BB',
        'blue-lighten2': '#CDD7E6',
        'blue-darken1': '#26436E',
        'blue-darken2': '#0E1827',
    },
};

const vuetify = createVuetify({
    theme: {
        defaultTheme: 'theme',
        themes: {
            theme,
        },
    },
    icons: {
        iconfont: 'mdiSvg', // Use the MDI SVG icon font
        values: mdiIcons, // Set the imported icons module as the values
    },
});

import { loadFonts } from './plugins/webfontloader';
loadFonts();

const app = createApp(App);

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import {
    setupCalendar,
    Calendar,
    DatePicker as VCalendarDatePicker,
} from 'v-calendar';

// Use plugin defaults (optional)
app.use(setupCalendar, {});

// Use the components
app.component('VCalendar', Calendar);
app.component('VCalendarDatePicker', VCalendarDatePicker);

app.config.globalProperties.$filters = filters;

export const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.VUE_APP_APPINSIGHTS_INSTRUMENTATION_KEY,
    },
});
appInsights.loadAppInsights();

Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [process.env.VUE_APP_DIFFO_API_BASE_URL],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    beforeSend(event, hint) {
        const error = hint.originalException;
        if (error && error.message && error.message.includes('401')) {
            return null;
        }
        return event;
    },
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

import _global from './mixins/_globals';
import betaMixin from './mixins/betaMixin';
import currencyMixin from './mixins/currencyMixin';
import formRules from './mixins/formRules';
import vehicleMixin from './mixins/vehicleMixin';
import vehicleDefaultsMixin from './mixins/vehicleDefaultsMixin';
import fuelMixin from './mixins/fuelMixin';
import wageMixin from './mixins/wageMixin';
import downloadCSV from './mixins/downloadCSV';
import dailyAllowanceMixin from './mixins/dailyAllowanceMixin';
import vehicleIcons from './mixins/vehicleIcons';
import workTypeMixin from './mixins/workTypeMixin';
import calculationMixin from './mixins/calculationMixin';
import emissionStandardMixin from './mixins/emissionStandardMixin';
import payrollHeaderMixin from './mixins/payrollHeaderMixin';
import bonusesMixin from './mixins/bonusesMixin';

app.mixin(_global);
app.mixin(betaMixin);
app.mixin(currencyMixin);
app.mixin(vehicleMixin);
app.mixin(vehicleDefaultsMixin);
app.mixin(fuelMixin);
app.mixin(formRules);
app.mixin(downloadCSV);
app.mixin(dailyAllowanceMixin);
app.mixin(vehicleIcons);
app.mixin(wageMixin);
app.mixin(workTypeMixin);
app.mixin(calculationMixin);
app.mixin(emissionStandardMixin);
app.mixin(payrollHeaderMixin);
app.mixin(bonusesMixin);

app.use(vuetify);
app.use(router);
app.use(store);
app.use(i18n);
app.use(VueCookies);
app.use(VueSocialChat);
app.use(i18n);

app.component('ErrorMessage', ErrorMessage);

// Configure VeeValidate (optional)
configure({
    validateOnInput: true, // Validate on input events (default: false)
});

const metaManager = createMetaManager();

app.use(metaPlugin, {
    keyName: 'metaInfo',
    refreshOnceOnNavigation: true,
});

app.use(metaManager);

app.mount('#app');
