export default {
    data() {
        return {
            workTypes: [
                {
                    text: this.$t('event.Drive'),
                    value: 1,
                    img: '/img/workTypeIcons/drive.png',
                    colorImg: '/img/workTypeIcons/drive_colored.png',
                    color: 'green',
                    event: true,
                },
                {
                    text: this.$t('event.Load'),
                    value: 2,
                    img: '/img/workTypeIcons/load.png',
                    colorImg: '/img/workTypeIcons/load_colored.png',
                    color: 'lightgreen',
                    event: true,
                },
                {
                    text: this.$t('event.Unload'),
                    value: 3,
                    img: '/img/workTypeIcons/unload.png',
                    colorImg: '/img/workTypeIcons/unload_colored.png',
                    color: 'palegreen',
                    event: true,
                },
                {
                    text: this.$t('event.Wait'),
                    value: 4,
                    img: '/img/workTypeIcons/wait.png',
                    colorImg: '/img/workTypeIcons/waiting_colored.png',

                    color: 'yellow',
                    event: true,
                },
                {
                    text: this.$t('event.Break'),
                    value: 5,
                    img: '/img/workTypeIcons/break.png',
                    colorImg: '/img/workTypeIcons/break_colored.png',
                    color: 'dodgerblue',
                    event: true,
                },
                {
                    text: this.$t('event.Paid break'),
                    value: 6,
                    img: '/img/workTypeIcons/paidBreak.png',
                    colorImg: '/img/workTypeIcons/paid_break_colored.png',
                    color: 'blue',
                    event: true,
                },
                {
                    text: this.$t('event.Refuel'),
                    value: 7,
                    img: '/img/workTypeIcons/refuel.png',
                    colorImg: '/img/workTypeIcons/refuel_colored.png',
                    color: 'orange',
                    event: true,
                },
                {
                    text: this.$t('event.Maintenance'),
                    value: 8,
                    img: '/img/workTypeIcons/maintenance.png',
                    colorImg: '/img/workTypeIcons/maintenance_colored.png',
                    color: 'red',
                    event: true,
                },
                {
                    text: this.$t('event.Others'),
                    value: 9,
                    img: '/img/workTypeIcons/others.png',
                    colorImg: '/img/workTypeIcons/others_colored.png',
                    color: 'dimgray',
                    event: true,
                },
                {
                    text: this.$t('event.Queue'),
                    value: 10,
                    img: '/img/workTypeIcons/queue.png',
                    colorImg: '/img/workTypeIcons/queue_colored.png',
                    color: 'gold',
                    event: true,
                },
                {
                    text: this.$t('event.Contract'),
                    value: 11,
                    img: '',
                    color: '#37609D',
                    event: false,
                },
                {
                    text: this.$t('event.CompensateHours'),
                    value: 12,
                    img: '',
                    color: '#37609D',
                    event: false,
                },
                {
                    text: this.$t('event.OtherPaidAbsence'),
                    value: 13,
                    img: '',
                    color: '#37609D',
                    event: false,
                },
                {
                    text: this.$t('event.UnpaidLeave'),
                    value: 14,
                    img: '/',
                    color: '#37609D',
                    event: false,
                },
                {
                    text: this.$t('event.SickLeave'),
                    value: 15,
                    img: '',
                    color: '#37609D',
                    event: false,
                },
                {
                    text: this.$t('event.AccidentCompensationHours'),
                    value: 16,
                    img: '',
                    color: '#37609D',
                    event: false,
                },
                {
                    text: this.$t('event.ChildIllness'),
                    value: 17,
                    img: '',
                    color: '#37609D',
                    event: false,
                },
                {
                    text: this.$t('event.Holiday'),
                    value: 18,
                    img: '',
                    color: '#37609D',
                    event: false,
                },
                {
                    text: this.$t('event.EmptyDrive'),
                    value: 19,
                    img: '/img/workTypeIcons/empty_drive.png',
                    colorImg: '/img/workTypeIcons/empty_drive_colored.png',
                    color: 'yellowgreen',
                    event: true,
                },
            ],
        };
    },
};
