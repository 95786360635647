import i18n from '../i18n';
import dayjs from 'dayjs';
import localeFi from 'dayjs/locale/fi';

const setTimeZone = (value) => {
    let time = new Date(value);
    if (value && value[value.length - 1] != 'Z') {
        time = new Date(time.getTime() - time.getTimezoneOffset() * 60000);
    }
    return time;
};

export const filters = {
    formatDate: function (value) {
        if (value) {
            let time = setTimeZone(value);
            return (
                ('0' + time.getDate()).slice(-2) +
                '.' +
                ('0' + (time.getMonth() + 1)).slice(-2) +
                '.' +
                time.getFullYear() +
                ' ' +
                ('0' + time.getHours()).slice(-2) +
                ':' +
                ('0' + time.getMinutes()).slice(-2)
            );
        }
    },

    stringToDate: function (value) {
        let time = setTimeZone(value);
        return time;
    },

    formatDateTime: function (value) {
        let valueCpy = value;

        // If the value is in ISO string format, make sure it's treated as UTC
        if (
            typeof value === 'string' &&
            value.includes('T') &&
            !value.endsWith('Z')
        ) {
            valueCpy = value + 'Z';
        }
        return dayjs(valueCpy).locale(localeFi).format('DD.MM.YYYY HH:mm');
    },

    formatDateTimeWithSeconds: function (value) {
        return dayjs(value).locale(localeFi).format('DD.MM.YYYY HH:mm:ss');
    },

    formatCurrency: function (value) {
        if (value != null && value != undefined) {
            if (!isNaN(value)) {
                return i18n.currency === '$'
                    ? Number(value).toFixed(2).toLocaleString('en-us')
                    : Number(value)
                          .toFixed(2)
                          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')
                          .replace(/\./g, ',');
            } else return value;
        }
    },

    roundToDecimals: function (value, precision) {
        if (precision < 1) return value;
        let multiplier = 10 * precision;
        return Math.round((value + Number.EPSILON) * multiplier) / multiplier;
    },

    formatDateInput: function (value) {
        return dayjs(value).format('DD.MM.YYYY');
    },

    decimalNumber: function (value) {
        const str = value.toFixed(2);
        return str.replace(/\./g, ',');
    },
    replaceCommaToDot: function (value) {
        return Number(value.replace(/,/g, '.'));
    },
    setHourToHourMin: function (value) {
        const hours = Math.floor(value);
        const mins = Math.floor((value - hours) * 60);
        return hours + ' ' + i18n.global.t('hour unit') + ' ' + mins + 'm';
    },
    setEmptyItem: function (value) {
        return !value || value === '' ? '-' : value;
    },
    setNullNumber: function (value) {
        return value === null ? '-' : value;
    },
    setDays: function (value) {
        return value ? value / 8 : 0;
    },
};
