import api from './api';
import cache from './cacheFunction';

export default {
    getUser() {
        return api.get('account/me');
    },
    get(orgId) {
        return api.get('Organisation/' + orgId);
    },
    getAll() {
        return api.get('Organisation/all');
    },
    update(organisation) {
        cache.deleteAll();
        return api.put('Organisation/' + organisation.id, organisation);
    },
    create(organisation) {
        cache.deleteAll();
        return api.post('Organisation', organisation);
    },
    setToBeDeleted(organisation, mode) {
        cache.deleteAll();
        return api.put('Organisation/' + organisation.id + '/SetDelete', mode, {
            headers: { 'Content-type': 'application/json' },
        });
    },
    getUsers(orgId) {
        return api.get('Organisation/' + orgId + '/users');
    },
    updateUser(user) {
        cache.deleteAll();
        return api.put(
            'Organisation/' + user.organisationId + '/users/' + user.id,
            user
        );
    },
    deleteUser(user) {
        cache.deleteAll();
        return api.delete(
            'Organisation/' + user.organisationId + '/users/' + user.id
        );
    },
    getExt(orgId) {
        return api.get('Organisation/' + orgId + '/ExternalCredentials');
    },
    postExt(orgId, ext) {
        cache.deleteAll();
        return api.post('Organisation/' + orgId + '/ExternalCredentials', ext);
    },
    putExt(orgId, ext) {
        console.log(orgId);
        cache.deleteAll();
        return api.put('Organisation/' + orgId + '/ExternalCredentials', ext);
    },
    delExt(orgId, ext) {
        cache.deleteAll();
        return api.delete('Organisation/' + orgId + '/ExternalCredentials', {
            data: {
                ...ext,
            },
        });
    },
    fetchAvailableVehicles(serviceName, orgId) {
        let params = {
            serviceName,
            organisationId: orgId,
        };

        return api.post('Organisation/FetchExternalVehicles', null, {
            params,
        });
    },
    fetchAvailableDrivers(serviceName, orgId) {
        let params = {
            serviceName,
            organisationId: orgId,
        };

        return api.post('Organisation/FetchExternalDrivers', null, {
            params,
        });
    },
    getExternalVehicles(serviceName, orgId) {
        let params = { serviceName, organisationId: orgId };
        return api.get('Organisation/ExternalVehicles', { params });
    },
    postExternalVehicle(vehicle, serviceName, orgId) {
        cache.deleteAll();
        let params = { serviceName, organisationId: orgId };
        return api.post('Organisation/ExternalVehicles', vehicle, { params });
    },
    deleteExternalVehicle(registerNumber, orgId) {
        cache.deleteAll();
        let params = { registerNumber, organisationId: orgId };
        return api.delete('Organisation/ExternalVehicles', { params });
    },
    fetchExtTasks(ext, orgId = null) {
        cache.deleteAll();
        let params = {
            serviceName: ext,
            organisationId: orgId,
        };
        return api.put('Organisation/FetchExternalData', null, {
            params: params,
        });
    },
    getFetchStatus(ext, orgId, vehicles) {
        let params = { serviceName: ext, vehicles };
        if (orgId) params.organisationId = orgId;

        return api.get('Organisation/FetchStatus', {
            params: params,
        });
    },
    getFetchDriverStatus(ext, orgId, drivers) {
        let params = { serviceName: ext, drivers };
        if (orgId) params.organisationId = orgId;

        return api.get('Organisation/FetchDriverStatus', {
            params: params,
        });
    },
    getExtToken(ext, orgId) {
        let params = { organisationId: orgId };
        return api.post('Organisation/GetAccessToken', ext, { params });
    },
    deleteExternalTasks(orgId) {
        cache.deleteAll();
        return api.delete('Organisation/' + orgId + '/tasks', {
            params: {
                source: 'external',
            },
        });
    },
    deleteInternalTasks(orgId) {
        cache.deleteAll();
        return api.delete('Organisation/' + orgId + '/tasks', {
            params: {
                source: 'internal',
            },
        });
    },
    deleteExternalInvoices(orgId) {
        cache.deleteAll();
        return api.delete('Organisation/' + orgId + '/invoices', {
            params: {
                source: 'external',
            },
        });
    },
    deleteInternalInvoices(orgId) {
        cache.deleteAll();
        return api.delete('Organisation/' + orgId + '/invoices', {
            params: {
                source: 'internal',
            },
        });
    },
    getSiteUsers() {
        return api.get('Organisation/all/users');
    },
    setVehicleDefaults(orgId) {
        cache.deleteAll();
        return api.put(
            'Organisation/' + orgId + '/VehicleDetails/ApplyDefaults'
        );
    },
    getVehicleDetails(orgId) {
        return api.get('Organisation/' + orgId + '/VehicleDetails');
    },
    updateVehicleDetail(detail) {
        cache.deleteAll();
        return api.put(
            'Organisation/' +
                detail.organisationId +
                '/VehicleDetails/' +
                detail.id,
            detail
        );
    },
    postVehicleDetail(detail) {
        cache.deleteAll();
        return api.post(
            'Organisation/' + detail.organisationId + '/VehicleDetails/',
            detail
        );
    },
    getFeatures(orgId) {
        const fetchApi = api.get('Organisation/' + orgId + '/features');
        return cache.getAll('features', 60 * 60 * 1000, fetchApi);
    },
    getAllFeatures() {
        return api.get('Organisation/all/features');
    },
    updateFeatures(orgId, features) {
        cache.deleteAll();
        return api.put('Organisation/' + orgId + '/features', features);
    },
    postFeatures(orgId, features) {
        cache.deleteAll();
        return api.post('Organisation/' + orgId + '/features', features);
    },

    getFuelPrices(orgId) {
        return api.get('Organisation/' + orgId + '/FuelPrices');
    },
    createFuelPrices(orgId, fuelPrice) {
        cache.deleteAll();
        return api.post('Organisation/' + orgId + '/FuelPrices', fuelPrice);
    },
    updateFuelPrices(orgId, fuelPrice) {
        cache.deleteAll();
        return api.put(
            'Organisation/' + orgId + '/FuelPrices/' + fuelPrice.id,
            fuelPrice
        );
    },
    getFuelHistory(orgId) {
        return api.get('Organisation/' + orgId + '/FuelHistory');
    },
    getWageDetails(orgId) {
        return api.get('Organisation/' + orgId + '/WageDetails');
    },
    createWageDetails(orgId, details) {
        cache.deleteAll();
        return api.post('Organisation/' + orgId + '/WageDetails', details);
    },
    updateWageDetails(orgId, details) {
        cache.deleteAll();
        return api.put('Organisation/' + orgId + '/WageDetails/', details);
    },
    tryVolvoConnect(username, password) {
        return api.post('organisation/tryvolvoconnect', {
            username: username,
            password: password,
        });
    },
    createSalesforceTicket(info) {
        return api.post('/SalesForce', {
            company: info.company,
            name: info.name,
            email: info.email,
            phone: info.phone,
            subject: info.subject,
            description: info.description,
        });
    },
    createPayrollCodes(salaryCodes, organisationId) {
        cache.deleteAll();
        return api.post('/SalaryTypeCodes/' + organisationId, salaryCodes);
    },
    getPayrollCodes(organisationId) {
        return api.get('/SalaryTypeCodes/' + organisationId);
    },
    updatePayrollCodes(salaryCodes, organisationId) {
        cache.deleteAll();
        return api.put('SalaryTypeCodes/' + organisationId, salaryCodes);
    },
    getShiftAmounts() {
        return api.get('Organisation/Shifts/');
    },
};
