import { createStore } from 'vuex';
import { vuexOidcCreateStoreModule } from 'vuex-oidc';
import { oidcSettings } from './config/oidc';
import cacheFunction from './cacheFunction';
// NOTE: If you want to customize how tokens are stored you can use WebStorageStateStore from oidc-client (see bellow)
// Default storage is window.localStorage
// import { WebStorageStateStore } from 'oidc-client'

export default createStore({
    state: {
        selectCalc: [],
        currentCalcId: 0,
        currentCalculation: null,
        calculationMod: 0,
        editCalc: false,
        routeHistory: [],
        currentCalcIndex: 0,
        currentRevenueId: 0,
        currentVehicleId: 0,
        vehicleDetailsId: 0,
        defaultFuelPrice: null,
        wageId: 0,
        investmentId: 0,
        investmentTypeDetailsId: 0,
        investmentTypeDetails: null,
        combinationCostsPerHour: 0,
        combinationCostsPerKM: 0,
        timeBasedCosts: 0,
        distanceBasedCosts: 0,
        costs: 0,
        unitCount: 0,
        targetProfitPct: 0,
        targetProfitPctOld: 0,
        targetPrice: 0,
        revenue: 0,
        profit: 0,
        profitPct: 0,
        yearlyGigs: 0,
        yearlyRevenue: 0,
        yearlyProfit: 0,
        yearlyProfitPct: 0,
        yearlyGigDistance: 0,
        yearlyGigTime: 0,
        gigDistanceValue: 0,
        gigTimeValue: 0,
        organisationName: '',
        organisationId: null,
        count: 0,
        features: null,
        roles: null,
        taskFilters: {
            dates: [null, null],
            drivers: null,
            vehicles: [],
            customerId: '',
            done: true,
            upcoming: true,
            department: null,
            routes: [],
            sharingFilter: null,
            source: '',
        },
        vehicle: null,
        userId: null,
        formValidation: false,
        orgUsers: [],
        routes: [],
        currentRouteId: null,
        currentShiftId: null,
        vehicleDefaultValues: [
            {
                vehicleClass: 1,
                name: 'Car',
                image: '/img/icons/car.png',
                maintenanceCost: 0.002,
                fuelCost: 1.1,
                fuelType: 0,
                interestRate: 2.5,
                investmentDeductions: 100,
                vehicleTax: 580,
                residualValue: 16,
                insuranceAllRisk: 700,
                insuranceVehicle: 300,
                tyresCost: 125,
                tyreCost: 0.001,
                desc: '',
                flex: 3,
                countOfTyres: 4,
                category: 0,
                adBluePrice: 5,
            },
            {
                vehicleClass: 2,
                name: 'Van',
                image: '/img/icons/van.png',
                maintenanceCost: 0.002,
                fuelCost: 1.1,
                fuelType: 0,
                interestRate: 2.5,
                investmentDeductions: 150,
                vehicleTax: 274,
                residualValue: 16,
                insuranceAllRisk: 700,
                insuranceVehicle: 300,
                tyresCost: 175,
                tyreCost: 0.001,
                desc: '',
                //calculationId: 0,
                flex: 3,
                countOfTyres: 4,
                category: 0,
                adBluePrice: 5,
            },
            {
                vehicleClass: 3,
                name: 'Truck',
                image: '/img/icons/truck.png',
                maintenanceCost: 0.002,
                fuelCost: 1.1,
                fuelType: 0,
                interestRate: 2.5,
                investmentDeductions: 400,
                vehicleTax: 927,
                residualValue: 16,
                insuranceAllRisk: 1300,
                insuranceVehicle: 1300,
                tyresCost: 0.001,
                tyreCost: 0.001,
                desc: '',
                //calculationId: 0,
                flex: 3,
                countOfTyres: 8,
                category: 0,
                adBluePrice: 5,
            },
            {
                vehicleClass: 4,
                name: 'TractorUnit',
                image: '/img/icons/tractor-unit.png',
                maintenanceCost: 0.002,
                fuelCost: 1.1,
                fuelType: 0,
                interestRate: 2.5,
                investmentDeductions: 400,
                vehicleTax: 1328,
                residualValue: 16,
                insuranceAllRisk: 1300,
                insuranceVehicle: 1300,
                tyresCost: 500,
                tyreCost: 0.001,
                desc: '',
                //calculationId: 0,
                flex: 3,
                countOfTyres: 8,
                category: 0,
                adBluePrice: 5,
            },
            {
                vehicleClass: 5,
                name: 'Semitrailer',
                image: '/img/icons/semi-trailer.png',
                maintenanceCost: 0.002,
                fuelCost: 1.1,
                fuelType: 0,
                interestRate: 2.5,
                investmentDeductions: 400,
                vehicleTax: 1328,
                residualValue: 16,
                insuranceAllRisk: 1400,
                insuranceVehicle: 1400,
                tyresCost: 357.14,
                tyreCost: 0.001,
                desc: '',
                //calculationId: 0,
                flex: 3,
                countOfTyres: 14,
                category: 0,
                adBluePrice: 5,
            },
            {
                vehicleClass: 6,
                name: 'Fulltrailer',
                image: '/img/icons/full-trailer.png',
                maintenanceCost: 0.002,
                fuelCost: 1.1,
                fuelType: 0,
                interestRate: 2.5,
                investmentDeductions: 400,
                vehicleTax: 1328,
                residualValue: 16,
                insuranceAllRisk: 1400,
                insuranceVehicle: 1400,
                tyresCost: 333.33,
                tyreCost: 0.001,
                desc: '',
                //calculationId: 0,
                flex: 3,
                countOfTyres: 18,
                category: 0,
                adBluePrice: 5,
            },
            {
                vehicleClass: 7,
                name: 'HCT-combination',
                image: '/img/icons/hct-combination.png',
                maintenanceCost: 0.002,
                fuelCost: 1.1,
                fuelType: 0,
                interestRate: 2.5,
                investmentDeductions: 400,
                vehicleTax: 1328,
                residualValue: 16,
                insuranceAllRisk: 1400,
                insuranceVehicle: 1400,
                tyresCost: 363.64,
                tyreCost: 0.001,
                desc: '',
                //calculationId: 0,
                flex: 3,
                countOfTyres: 22,
                category: 0,
                adBluePrice: 5,
            },
            {
                vehicleClass: 8,
                name: 'Bus',
                image: '/img/icons/bus.png',
                maintenanceCost: 0.002,
                fuelCost: 1.1,
                fuelType: 0,
                interestRate: 2.5,
                investmentDeductions: 400,
                vehicleTax: 1328,
                residualValue: 16,
                insuranceAllRisk: 1300,
                insuranceVehicle: 1300,
                tyreCost: 0.001,
                desc: '',
                // calculationId: 0,
                flex: 3,
                countOfTyres: 6,
                category: 0,
                adBluePrice: 5,
            },
            {
                vehicleClass: 9,
                name: 'Machine',
                image: '/img/icons/excavator.png',
                maintenanceCost: 0.002,
                fuelCost: 1.1,
                fuelType: 0,
                interestRate: 2.5,
                investmentDeductions: 100,
                vehicleTax: 580,
                residualValue: 16,
                insuranceAllRisk: 700,
                insuranceVehicle: 300,
                tyreCost: 0.001,
                desc: '',
                //calculationId: 0,
                flex: 3,
                countOfTyres: 4,
                category: 1,
                adBluePrice: 5,
            },
        ],
        selectedCurrency: '€', // Default currency
        payrollHeaders: [],
        pivotPayrollTable: false,
        timesheetSummaryTab: 0,
    },
    getters: {
        costs(state) {
            return Number(state.costs);
        },
        counter(state) {
            return state.count;
        },
        features(state) {
            return state.features;
        },
        roles(state) {
            return state.roles;
        },
        calculation(state) {
            return state.currentCalculation;
        },
        taskFilters(state) {
            return state.taskFilters;
        },
        conversionFactor: (state) => {
            const multiplier =
                state.selectedCurrency === 'SEK' ||
                state.selectedCurrency === 'kr'
                    ? 10
                    : 1;

            // Use map to create a new array with modified maintenanceCost values
            const modifiedValues = state.vehicleDefaultValues.map((v) => ({
                ...v,
                maintenanceCost: v.maintenanceCost * multiplier,
                fuelCost: v.fuelCost * multiplier,
                interestRate: v.interestRate * multiplier,
                investmentDeductions: v.investmentDeductions * multiplier,
                vehicleTax: v.vehicleTax * multiplier,
                residualValue: v.residualValue * multiplier,
                insuranceAllRisk: v.insuranceAllRisk * multiplier,
                insuranceVehicle: v.insuranceVehicle * multiplier,
                tyresCost: v.tyresCost * multiplier,
                tyreCost: v.tyreCost * multiplier,
                adBluePrice: v.adBluePrice * multiplier,
            }));

            return modifiedValues;
        },
    },
    mutations: {
        setUserId(state, id) {
            state.userId = id;
        },
        setSelectCalculations(state, payload) {
            state.selectCalc = [...payload];
        },
        totalCosts(state) {
            state.costs = Number(
                state.distanceBasedCosts + state.timeBasedCosts,
            );
        },
        targetPrice(state) {
            state.targetPrice =
                Number(state.costs) /
                (1 - state.targetProfitPct / 100.0) /
                +state.unitCount;
        },
        revenue(state) {
            state.revenue = state.targetPrice * +state.unitCount;
        },
        profit(state) {
            state.profit = state.revenue - state.costs;
            state.profitPct = (state.profit / state.revenue) * 100.0;
            state.yearlyProfit = state.profit * state.yearlyGigs;
            state.yearlyProfitPct = state.profitPct;
            state.yearlyGigDistance = state.gigDistanceValue * state.yearlyGigs;
            state.yearlyGigTime = state.gigTimeValue * state.yearlyGigs;
        },
        yearlyRevenue(state) {
            state.yearlyRevenue = state.revenue * state.yearlyGigs;
            state.yearlyProfit = state.profit * state.yearlyGigs;
            state.yearlyGigDistance = state.gigDistanceValue * state.yearlyGigs;
            state.yearlyGigTime = state.gigTimeValue * state.yearlyGigs;
        },
        calculationModified(state) {
            state.calculationMod++;
        },
        increment(state, payload) {
            state.count += payload;
        },
        setCalculation(state, calc) {
            state.currentCalculation = calc;
        },
        setCurrentFuelPrice(state, fuelPrice) {
            state.defaultFuelPrice = { ...fuelPrice };
        },
        setInvestment(state, inv) {
            state.investmentTypeDetails = inv;
        },
        setOrganisation(state, org) {
            state.organisation = org;
            state.organisationName = org.name;
        },
        setFeatures(state, features) {
            state.features = features;
        },
        setRoles(state, roles) {
            state.roles = Array.isArray(roles) ? roles : [roles];
        },
        setVehicle(state, vehicle) {
            state.vehicle = vehicle;
        },
        setTaskFilters(state, filters) {
            state.taskFilters = filters;
        },
        setCurrentVehicleId(state, id) {
            state.currentVehicleId = id;
        },
        setEditCalc(state, edit) {
            state.editCalc = edit;
        },
        setRoutes(state, route) {
            // when creating new calc and user want to revert the step,
            //this will check if the vehicle type has been set yet
            state.routeHistory = [...state.routeHistory, route];
        },
        clearRoutes(state) {
            state.routeHistory = [];
        },
        validateDateForm(state, validate) {
            state.formValidation = validate;
        },
        setUsers(state, users) {
            state.orgUsers = users;
        },
        setOrgRoutes(state, routes) {
            state.routes = [...routes];
        },
        setCurrentRouteId(state, currentRouteId) {
            state.currentRouteId = currentRouteId;
        },
        setCurrentShiftId(state, shiftId) {
            state.currentShiftId = shiftId;
        },
        setSelectedCurrency(state, currency) {
            state.selectedCurrency = currency;
        },
        setPayrollHeaders(state, headers) {
            state.payrollHeaders = headers;
        },
        setPivotPayrollTable(state) {
            state.pivotPayrollTable = !state.pivotPayrollTable;
        },
        setTimesheetSummaryTab(state, tab) {
            state.timesheetSummaryTab = tab;
        },
    },
    actions: {
        increment(state, payload) {
            state.commit('increment', payload);
        },
        checkCalculation({ commit, state }, api) {
            return new Promise((resolve) => {
                if (state.currentCalcId) {
                    api.get(state.currentCalcId)
                        .then((res) => {
                            commit('setCalculation', res.data);
                            resolve(res.data);
                        })
                        .catch((error) => {
                            console.log(error);
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        getFeatures({ commit, state }, api) {
            return new Promise((resolve) => {
                api.getFeatures(state.organisationId)
                    .then((res) => {
                        if (res) {
                            commit('setFeatures', res);
                            resolve(res);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        resolve(false);
                    });
            });
        },
        async logout(context) {
            let keysToSave = ['timesheetHeaders', 'payrollHeaders'];

            for (const key of Object.entries(localStorage)) {
                if (!keysToSave.includes(key)) {
                    await localStorage.removeItem(key);
                }
            }
            cacheFunction.deleteAll();

            // Dispatch the logout action from vuex-oidc
            context.dispatch('oidcStore/signOutOidc');
        },
    },

    modules: {
        oidcStore: vuexOidcCreateStoreModule(
            //https://github.com/perarnborg/vuex-oidc/wiki
            oidcSettings,
            // NOTE: If you do not want to use localStorage for tokens, in stead of just passing oidcSettings, you can
            // spread your oidcSettings and define a userStore of your choice
            // {
            //   ...oidcSettings,
            //   userStore: new WebStorageStateStore({ store: window.sessionStorage })
            // },
            // Optional OIDC store settings
            {
                namespaced: true,
                dispatchEventsOnWindow: true,
            },
            // Optional OIDC event listeners
            {
                userLoaded: (user) => console.log('OIDC user is loaded:', user),
                userUnloaded: () => console.log('OIDC user is unloaded'),
                accessTokenExpiring: () =>
                    console.log('Access token will expire'),
                accessTokenExpired: () =>
                    console.log('Access token did expire'),
                silentRenewError: () => console.log('OIDC user is unloaded'),
                automaticSilentRenewError: (payload) =>
                    console.log(
                        'Automatic silent renew failed.',
                        payload.error,
                    ),
                userSignedOut: () => console.log('OIDC user is signed out'),
                oidcError: (payload) => console.log('OIDC error', payload),
            },
        ),
    },
});
