export default {
    data() {
        return {
            payrollHeaders: [
                'driverName',
                'amountOfshifts',
                'employeeNumber',
                'compensateHours',
                'otherPaidAbsence',
                'unpaidLeave',
                'sickLeave',
                'accidentCompensationHours',
                'childIllness',
                'contract',
                'eventDetails',
                'vacationdays',
                'fullDomesticDailyAllowance',
                'halfDomesticDailyAllowance',
                'increasedDomesticFullDailyAllowance',
                'fullAbroadDailyAllowance',
                'halfAbroadDailyAllowance',
                'increasedAbroadFullDailyAllowance',
                'overTime50Percent',
                'overtime100Percent',
                'eveningSupplementHours',
                'nightSupplementHours',
                'sundayOrBankSupplementHours',
                'totalHours',
                'guaranteedHours',
                'workingTime',
                'waitingHours',
                'waitingInstances',
                'queuingHours',
                'queueingInstances',
                'paidBreakHours',
                'paidBreakInstances',
                'breakHours',
                'breakInstances',
                'drivingInstances',
                'loadingInstances',
                'unloadingInstances',
                'refuelingInstances',
                'maintenanceInstances',
                'maintenanceHours',
                'otherInstances',
                'adrHours',
                'craneHours',
                'dirtyJobHours',
                'hctHours',
                'dayOffBonus'
            ],
            mepcoHeaders: {
                shifts: [
                    'date',
                    'name',
                    'employeeNumber',
                    'salaryTypeId',
                    'amount',
                    'pieces',
                    'routeCode',
                    'vehicleCode',
                ],
                contracts: [
                    'date',
                    'name',
                    'employeeNumber',
                    'salaryTypeId',
                    'amount',
                    'pieces',
                    'routeCode',
                    'vehicleCode',
                ],
                vacations: [
                    'employeeNumber',
                    'name',
                    'startDate',
                    'endDate',
                    'salaryTypeId',
                ],
                leaves: [
                    'employeeNumber',
                    'name',
                    'startDate',
                    'endDate',
                    'salaryTypeId',
                    'pieces',
                ],
            },
            mepcoDetailedHeaders: {
                shifts: [
                    'date',
                    'name',
                    'employeeNumber',
                    'salaryTypeId',
                    'salaryTypeName',
                    'amount',
                    'pieces',
                    'routeCode',
                    'route',
                    'vehicleCode',
                ],
                contracts: [
                    'date',
                    'name',
                    'employeeNumber',
                    'salaryTypeId',
                    'salaryTypeName',
                    'amount',
                    'pieces',
                    'routeCode',
                    'route',
                    'vehicleCode',
                ],
                vacations: [
                    'startDate',
                    'endDate',
                    'employeeNumber',
                    'name',
                    'salaryTypeId',
                ],
                leaves: [
                    'startDate',
                    'endDate',
                    'employeeNumber',
                    'name',
                    'salaryTypeId',
                    'pieces',
                ],
            },
            fennoaHeaders: [
                'employeeNumber',
                'salaryTypeId',
                'date',
                'pieces',
                'price',
                'multiplier',
                'description',
                'startDate',
                'endDate',
                'absences',
                'routeCode',
            ],
        };
    },
};
